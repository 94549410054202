import types from "@store/types";

// ** Initial State
const initialState = {
  tsm: null,
  unmapped_outlets: null
};

const tsmconfigReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_TSM:
      return { ...state, tsm: action.value };

    case types.GET_UNMAPPED_OUTLETS:
      const _unmapped_outlets = action?.value?.map((value) => {
        return {
          value: value,
          label: value
        };
      });
      return {
        ...state,
        unmapped_outlets: _unmapped_outlets
      };

    default:
      return state;
  }
};

export default tsmconfigReducer;
