import types from "@store/types";

// ** Initial State
const initialState = {
  complaints: null,
  dealer: null,
  location: null,
  levels: [],
};

const adminReducr = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_COMPLAINTS:
      return { ...state, complaints: action.value?.data };
    case types.GET_LEVELS:
      return { ...state, levels: action.value?.data };
    default:
      return state;
  }
};

export default adminReducr;
