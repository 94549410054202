import types from "@store/types";

// ** Initial State
const initialState = {
  level_tickets: null
};

const ciReducer = (state = initialState, action) => {
  switch (action.type) {

    case types.GET_LEVEL_TICKETS:
      return { ...state, level_tickets: action.value };

    default:
      return state;
  }
};

export default ciReducer;
