const types = {
  // CMS
  GET_MODELS: "GET_MODELS",
  ADD_TICKETS: "ADD_TICKETS",
  GET_TICKETS: "GET_TICKETS",
  GET_TICKETS_WITHOUT_PARAMS: "GET_TICKETS_WITHOUT_PARAMS",
  GET_UNJUSTIFIED_REASONS: "GET_UNJUSTIFIED_REASONS",
  GET_YTD_DETAILS: "GET_YTD_DETAILS",
  GET_ACTIVE_LEVEL_MEMBERS: "GET_ACTIVE_LEVEL_MEMBERS",
  GET_CHART_DATA: "GET_CHART_DATA",

  // HOLDUP
  GET_JOB_CARDS: "GET_JOB_CARDS",
  GET_HOLDUP_REASON: "GET_HOLDUP_REASON",

  // TSMCONFIG
  GET_TSM: "GET_TSM",
  GET_UNMAPPED_OUTLETS: "GET_UNMAPPED_OUTLETS",

  // DEALERCONFIG
  GET_DEALERS: "GET_DEALERS",
  ADDUPDATE_DEALER: "ADDUPDATE_DEALER",

  // CI
  GET_LEVEL_TICKETS: "GET_LEVEL_TICKETS",

  // NA
  GET_NA_DATA: "GET_NA_DATA",
  GET_NA_REPORT: "GET_NA_REPORT",
  GET_NA_REASON: "GET_NA_REASON",
  GET_SUMMARY_REPORT: "GET_SUMMARY_REPORT",
  GET_NA_DUMP: "GET_NA_DUMP",
  GET_CITY_STATE: "GET_CITY_STATE",

  //HelpDeskData
  GET_TECH_HELP_DESK_DATA: "GET_TECH_HELP_DESK_DATA",

  //Admin
  GET_COMPLAINTS: "GET_COMPLAINTS",
  GET_DEALER_BY_EMAIL: "GET_DEALER_BY_EMAIL",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LEVELS: "GET_LEVELS",

  //TimeLine
  GET_TIMELINE_DATA: "GET_TIMELINE_DATA",
};

export default types;
