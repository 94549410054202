import types from "@store/types";

// ** Initial State
const initialState = {
  techHlpDesk_data: null,
};

const techHelpDeskReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TECH_HELP_DESK_DATA:
      return {
        ...state,
        techHlpDesk_data: action.value?.data,
        techHlpDesk_data_count: action.value?.data_counts,
        total_count: action.value?.total_count,
      };
    default:
      return state;
  }
};

export default techHelpDeskReducer;
