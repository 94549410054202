import types from "@store/types";
import _ from "lodash";

// ** Initial State
const initialState = {
  job_cards: null,
  holdup_reasons: {
    service: [],
    bodyshop: [],
  },
};

const holdupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_JOB_CARDS:
      return {
        ...state,
        job_cards: action.value,
      };

    case types.GET_HOLDUP_REASON:
      const _holdup_reasons = action?.value?.map((value) => {
        return {
          value: value,
          label: value,
        };
      });
      return {
        ...state,
        holdup_reasons: {
          ...state.holdup_reasons,
          [action.key]: _holdup_reasons,
        },
      };

    default:
      return state;
  }
};

export default holdupReducer;
