import types from "@store/types";
import _ from "lodash";

// ** Initial State
const initialState = {
  add_ticket: {},
  ticket_list: null,
  unjustified_reasons: [],
  ytd_details: {},
  panel_members_unique_emails: [],
  chart_data: [],
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TICKETS:
      return { ...state, add_ticket: action.value };

    case types.GET_TICKETS:
      return { ...state, ticket_list: action.value };

    case types.GET_TICKETS_WITHOUT_PARAMS:
      return { ...state, tickets: action.value };

    case types.GET_UNJUSTIFIED_REASONS:
      return { ...state, unjustified_reasons: action.value };

    case types.GET_CHART_DATA:
      return { ...state, chart_data: action.value };

    case types.GET_YTD_DETAILS:
      return { ...state, ytd_details: action.value };

    case types.GET_ACTIVE_LEVEL_MEMBERS:
      return { ...state, panel_members_unique_emails: action.value?.data };

    default:
      return state;
  }
};

export default cmsReducer;
