import types from "@store/types";

// ** Initial State
const initialState = {
  timelineData: null,
};

const timelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TIMELINE_DATA:
      return { ...state, timelineData: action.value };

    default:
      return state;
  }
};

export default timelineReducer;
