import types from "@store/types";

// ** Initial State
const initialState = {
  models: [],
  models_options: [],
  is_loading: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MODELS:
      const _models = action.value;
      const _models_options = _models.map((m) => {
        return {
          value: m?.name,
          label: m?.name,
          channel: m?.channel
        };
      });

      return {
        ...state,
        models: _models,
        models_options: _models_options,
      };

    case "IS_LOADING":
      return { ...state, is_loading: action.value };

    default:
      return state;
  }
};

export default appReducer;
