// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import app from "./app";
import cms from "./dashboard/cms";
import holdup from "./dashboard/holdup";
import tsmconfig from "./dashboard/tsmconfig";
import ci from "./dashboard/ci";
import dealerconfig from "./dashboard/dealerconfig";
import na from "./dashboard/na";
import techHelpDesk from "./dashboard/technicalHelpDesk";
import admin from "./dashboard/admin";
import timeLine from "./dashboard/timeline/index";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  app,
  cms,
  holdup,
  tsmconfig,
  dealerconfig,
  ci,
  na,
  techHelpDesk,
  admin,
  timeLine,
});

export default rootReducer;
