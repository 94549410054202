import types from "@store/types";
import _ from "lodash";

// ** Initial State
const initialState = {
  dealers: null,
};

const dealerconfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DEALERS:
      return {
        ...state,
        dealers: action.value,
      };

    case types.ADDUPDATE_DEALER:
      return {
        ...state,
        added_dealers: action.value,
      };

    default:
      return state;
  }
};

export default dealerconfigReducer;
