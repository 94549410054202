import types from "@store/types";

// ** Initial State
const initialState = {
  na_data: null,
};

const naReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_NA_DATA:
      return {
        ...state,
        na_data: action.value?.data,
        na_data_count: action.value?.data_counts,
        total_count: action.value?.total_count,
      };
    case types.GET_NA_REPORT:
      const report = action.value?.data;
      report.push(action.value?.total);
      return { ...state, na_report: report };
    case types.GET_NA_REASON:
      return { ...state, na_reason: action.value };
    case types.GET_SUMMARY_REPORT:
      const summaryReport = action.value?.data;
      summaryReport.push(action.value.total);
      return {
        ...state,
        summary_report: summaryReport,
      };
    case types.GET_NA_DUMP:
      return { ...state, na_dump: action.value };
    case types.GET_CITY_STATE:
      return { ...state, city_state_data: action.value };

    default:
      return state;
  }
};

export default naReducer;
